<template>
  <div>

    <template>
    <div id="breadcrumb-alignment" class="mb-1">
      <div class="d-flex justify-content-start breadcrumb-wrapper">
        <b-breadcrumb
          :items="breadCrumb()"
        />
      </div>
    </div>
  </template>

    <div v-if="item != null">

      <b-card>
        <b-row>
          <b-col md=12>

            <div class="demo-inline-spacing float-right ">
              
                <b-button
                  variant="danger"
                  class="mt-0 mb-1"
                  :to="{ name: 'edit-job-sites', params: { id: item._id } }"
                >
                <feather-icon
                    icon="EditIcon"
                    class="mr-25"
                  />
                  <span>Edit</span>
                </b-badge>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <b-row>
        
          <b-col
          cols="12"
          class="mb-2 mt-2 margin_bottom_zero_mobile"
          >
            <h4 class="mb-0">
              Project Site Details
            </h4>
            
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col
            cols="12"
            xl="6">
            
            <table class="mt-2 mt-xl-0 w-100">
              <tr class="mb-1">
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-1">
                        Project Site 
                      </h6>
                    <!--  <small>Monthly Sales</small> -->
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  <p class="wordBreak">{{item.site_name |capitalize}}</p>
                </td>
              </tr>

              <tr class="mb-1">
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-1">
                        Address 
                      </h6>
                    <!--  <small>Monthly Sales</small> -->
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  <p class="wordBreak">{{item.address}}</p>
                </td>
              </tr>

              <tr class="mb-1">
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-1">
                        Country 
                      </h6>
                    <!--  <small>Monthly Sales</small> -->
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  <p class="wordBreak">{{item.country}}</p>
                </td>
              </tr>

              <tr class="mb-1">
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-1">
                        QI Star/Number Count  
                      </h6>
                    <!--  <small>Monthly Sales</small> -->
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  <p class="wordBreak">{{item.qi_max}}</p>
                </td>
              </tr>

              <tr class="mb-1">
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-1">
                        Managing Agent Name 
                      </h6>
                    <!--  <small>Monthly Sales</small> -->
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  <p class="wordBreak">{{item.managing_agent_name | capitalize}}</p>
                </td>
              </tr>

              <tr class="mb-1" >
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-1">
                        Group Filter 
                      </h6>
                    
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  <p class="wordBreak">{{item.group_filter | capitalize}}</p>
                </td>
              </tr>

              <tr class="mb-1">
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-1">
                        Remarks 
                      </h6>
                    <!--  <small>Monthly Sales</small> -->
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  <p class="wordBreak">{{item.remarks | capitalize}}</p>
                </td>
              </tr>

              <tr class="mb-1">
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-1">
                        Allow Attendance 
                      </h6>
                    <!--  <small>Monthly Sales</small> -->
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  <p class="wordBreak">{{item.allow_attendance | capitalize}}</p>
                </td>
              </tr>
              <tr class="mb-1" v-if="item.allow_attendance == 'yes'">
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-1">
                        Attendance Mode 
                      </h6>
                    <!--  <small>Monthly Sales</small> -->
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  <p class="wordBreak">{{item.attendance_mode == 'gps'? 'GPS': 'Camera'}}</p>
                </td>
              </tr>

              <tr class="mb-1" v-if="item.allow_attendance == 'yes'">
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-1">
                        Enable Selfie 
                      </h6>
                    <!--  <small>Monthly Sales</small> -->
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  <p class="wordBreak">{{item.enable_selfie | capitalize}}</p>
                </td>
              </tr>

              <tr class="mb-1" v-if="item.attendance_mode == 'gps'">
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-1">
                        Radius (in meter)
                      </h6>
                    <!--  <small>Monthly Sales</small> -->
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  <p class="wordBreak">{{item.radius}}</p>
                </td>
              </tr>

              <tr class="mb-1" v-if="item.attendance_mode == 'camera'">
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-1">
                        Show Timestamp on Image
                      </h6>
                    <!--  <small>Monthly Sales</small> -->
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  <p class="wordBreak">{{item.timestamp_on_image | capitalize}}</p>
                </td>
              </tr>
              
              <tr class="mb-1" v-if="item.allow_attendance == 'yes'">
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-1">
                        Grace Period
                      </h6>
                    <!--  <small>Monthly Sales</small> -->
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  <p class="wordBreak">{{item.grace_period + ' min'}}</p>
                </td>
              </tr>

              <tr class="mb-1" v-if="item.allow_attendance == 'yes'">
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-1">
                        Allow NFC Mode
                      </h6>
                    <!--  <small>Monthly Sales</small> -->
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  <p class="wordBreak">{{item.nfc_mode | capitalize}}</p>
                </td>
              </tr>

              <tr class="mb-1">
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-1">
                        Restart Failed Tasks
                      </h6>
                    <!--  <small>Monthly Sales</small> -->
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  <p class="wordBreak">{{item.restart_failed_task | capitalize}}</p>
                </td>
              </tr>

              <tr class="mb-1">
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-1">
                        Start Expired Tasks
                      </h6>
                    <!--  <small>Monthly Sales</small> -->
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  <p class="wordBreak">{{item.start_expired_task | capitalize}}</p>
                </td>
              </tr>
              <tr class="mb-1">
                  <th class="pb-50">
                    <div class="d-flex align-items-center mr-2">
                    
                      <div class="ml-1">
                        <h6 class="mb-1">
                          Additional info on Defect
                        </h6>
                      <!--  <small>Monthly Sales</small> -->
                      </div>
                    </div>
                  </th>
                  <td class="pb-50">
                    <p class="wordBreak">{{ item.additional_info_defect | capitalize }}</p>
                  </td>
                </tr>

                <!-- <tr class="mb-1">
                  <th class="pb-50">
                    <div class="d-flex align-items-center mr-2">
                
                      <div class="ml-1">
                        <h6 class="mb-1">
                          Inventory Notification
                        </h6>
                      </div>
                    </div>
                  </th>
                  <td class="pb-50">
                    <p class="wordBreak">{{ item.inventory_notification | capitalize }}</p>
                  </td>
                </tr> -->

              

              

              
              
            </table>

          </b-col>

          <b-col
            cols="12"
            xl="6">
            
            <table class="mt-2 mt-xl-0 w-100">
              
              <tr class="mb-1">
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-1">
                        Prefix
                      </h6>
                    
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  <p class="wordBreak">{{item.prefix}}</p>
                </td>
              </tr>

              <tr class="mb-1">
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-1">
                        Postal Code
                      </h6>
                    
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  <p class="wordBreak">{{item.postal_code}}</p>
                </td>
              </tr>
              <tr class="mb-1">
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-1">
                        QI Rating
                      </h6>
                    
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  <p class="wordBreak">{{item.qi_type}}</p>
                </td>
              </tr>
              
              <tr class="mb-1">
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-1">
                        Department
                      </h6>
                    
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  <p class="wordBreak">{{item.department}}</p>
                </td>
              </tr>

              <tr class="mb-1">
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-1">
                        KPI Contract
                      </h6>
                    
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  <p class="wordBreak">{{item.kpi_contract | capitalize}}</p>
                </td>
              </tr>

              
              <tr class="mb-1" >
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-1">
                        Date Format 
                      </h6>
                    
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  <p class="wordBreak">{{formatType(item.pdf_date_format)}}</p>
                </td>
              </tr>

              <tr class="mb-1" >
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-1">
                        Time Format 
                      </h6>
                    
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  <p class="wordBreak">{{item.pdf_time_format == 'hh:mm A' ? '01:00 PM' : '13:00'}}</p>
                </td>
              </tr>
              <tr class="mb-1">
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-1">
                        No Photo Reporting 
                      </h6>
                    <!--  <small>Monthly Sales</small> -->
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  <p class="wordBreak">{{item.no_photo_reporting | capitalize}}</p>
                </td>
              </tr>
              <tr class="mb-1">
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-1">
                        Task Status Update Delay
                      </h6>
                    <!--  <small>Monthly Sales</small> -->
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  <p class="wordBreak">{{item.task_status_delay | capitalize}}</p>
                </td>
              </tr>
              <tr class="mb-1" v-if="item.task_status_delay == 'yes'">
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-1">
                        No of Delay Minute
                      </h6>
                    <!--  <small>Monthly Sales</small> -->
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  <p class="wordBreak">{{item.task_status_delay_min}}</p>
                </td>
              </tr>

              <tr class="mb-1">
                <th class="pb-50">
                  <div class="d-flex align-items-center mr-2">
                    
                    <div class="ml-1">
                      <h6 class="mb-1">
                        Contract Type
                      </h6>
                    <!--  <small>Monthly Sales</small> -->
                    </div>
                  </div>
                </th>
                <td class="pb-50">
                  <p class="wordBreak">{{item.site_type == 'hit_count' ? 'Headcount Based' : 'Performance Based'}}</p>
                </td>
              </tr>

              
              <tr class="mb-1">
                  <th class="pb-50">
                    <div class="d-flex align-items-center mr-2">
                    
                      <div class="ml-1">
                        <h6 class="mb-1">
                          Allow Inventory
                        </h6>
                      <!--  <small>Monthly Sales</small> -->
                      </div>
                    </div>
                  </th>
                  <td class="pb-50">
                    <p class="wordBreak">{{ item.inventory | capitalize }}</p>
                  </td>
                </tr>

                <tr class="mb-1">
                    <th class="pb-50">
                      <div class="d-flex align-items-center mr-2">
                    
                        <div class="ml-1">
                          <h6 class="mb-1">
                            Multiple Delivery
                          </h6>
                        <!--  <small>Monthly Sales</small> -->
                        </div>
                      </div>
                    </th>
                    <td class="pb-50">
                      <p class="wordBreak">{{ item.multiple_delivery | capitalize }}</p>
                    </td>
                  </tr>

                  <!-- <tr class="mb-1" v-if="item.inventory_notification == 'yes'">
                    <th class="pb-50">
                      <div class="d-flex align-items-center mr-2">
                
                        <div class="ml-1">
                          <h6 class="mb-1">
                            Notification will Triggered on
                          </h6>
                        </div>
                      </div>
                    </th>
                    <td class="pb-50">
                      <p class="wordBreak"> Day {{ item.inventory_notification_day  }}  - Time {{ item.inventory_notification_time }} </p>
                    </td>
                  </tr> -->

                  <tr class="mb-1">
                    <th class="pb-50">
                      <div class="d-flex align-items-center mr-2">
                  
                        <div class="ml-1">
                          <h6 class="mb-1">
                            Additional Information
                          </h6>
                        </div>
                      </div>
                    </th>
                    <td class="pb-50">
                      <p class="wordBreak">{{ item.additional_information | capitalize }}</p>
                    </td>
                  </tr>

              
            </table>

          </b-col>
        </b-row>
        <hr>


        <b-row class="mt-2 mb-2">
              <b-col md="12">
                  <gmap-map
                      ref="googleMap"
                      :center="mapStartLocation"
                      :zoom="zoom"
                      :options="{scrollwheel: false}"

                      style="width:100%;  height: 400px; overflow: auto;border: 2px solid black;border-radius: 4px"
                    >
                      
                        <!-- <gmap-marker
                          
                          class="markerCricle"
                          :position="mapStartLocation"
                        ></gmap-marker> -->

                        <gmap-polygon 
                        :paths="paths" 
                        :editable="false" 
                        :draggable="false"
                        >
                        </gmap-polygon>

                  </gmap-map>

              </b-col>
        </b-row>


        <b-row>
        
          <b-col
          cols="12"
          class="mb-2 mt-2"
          >
            <h4 class="mb-0">
              Shift Details
            </h4>
            
          </b-col>
        </b-row>

        <b-table sticky-header
          ref="refRangeTable"
          class="position-relative"
          :items="item.shifts"
          
          :fields="shiftColumns"
          primary-key="id" 
          >

          <template #cell(name)="items">
            
            <span>{{ items.item.shift }}</span>
          </template>
          <template #cell(start_day)="items">
            
            <span>{{ items.item.start_day}}</span>
          </template>

          <template #cell(end_day)="items">
            
            <span>{{ items.item.end_day }}</span>
          </template>

          <template #cell(start_time)="items">
            
            <!-- <span>{{ items.item.start_time | time}}</span> -->
            <span>{{ sitetime(items.item.start_time,item.pdf_time_format)}}</span>
          </template>

          <template #cell(end_time)="items">
            
            <!-- <span>{{ items.item.end_time |time }}</span> -->
            <span>{{ sitetime(items.item.end_time,item.pdf_time_format)}}</span>
          </template>

          <template #cell(holiday)="items">
            
            <span>{{ items.item.holiday | capitalize }}</span>
          </template>

          <template #cell(crew)="items">
            
            <span>{{ items.item.crew ? items.item.crew : '0'}}</span>
          </template>

          <template #cell(supervisor)="items">
            
            <span>{{ items.item.supervisor ? items.item.supervisor : '0' }}</span>
          </template>

        </b-table>


        <hr>
        <b-row>
        
          <b-col
          cols="12"
          class="mb-2 mt-2"
          >
            <h4 class="mb-0">
              Contract Period Details
            </h4>
            
          </b-col>
        </b-row>

        <b-table sticky-header
          ref="refRangeTable"
          class="position-relative"
          :items="item.contract_periods"
          
          :fields="contractColumns"
          primary-key="id" 
          >

          <template #cell(start_date)="items">
            
            <span>{{ sitedateRangePicker(items.item.start_date,item.pdf_date_format,'')}}</span>
          </template>

          <template #cell(end_date)="items">
            
            <!-- <span>{{ items.item.end_date |dateRangePicker}}</span> -->
            <span>{{ sitedateRangePicker(items.item.end_date,item.pdf_date_format,'')}}</span>
          </template>

          

        </b-table>

        <hr>

        <b-row>
        
          <b-col
          cols="12"
          class="mb-2 mt-2"
          >
            <h4 class="mb-0">
              Work Schedule Details
            </h4>
            
          </b-col>
        </b-row>

        <b-table sticky-header
          ref="refRangeTable"
          class="position-relative"
          :items="item.work_schedules"
          
          :fields="workScheduleColumns"
          primary-key="id" 
          >

          <template #cell(startTime)="items">
            
            <!-- <span>{{ items.item.startTime}}</span> -->
            <span>{{sitetime(items.item.startTime,item.pdf_time_format)}}</span>
          </template>

          <template #cell(endTime)="items">
            
            <!-- <span>{{ items.item.endTime}}</span> -->
            <span>{{sitetime(items.item.endTime,item.pdf_time_format)}}</span>
          </template>

          

        </b-table>
        <hr>
        <b-row>
        
          <b-col
          cols="12"
          class="mb-2 mt-2"
          >
            <h4 class="mb-0">
              Work Days Details
            </h4>
            
          </b-col>
        </b-row>

        <b-table sticky-header
          ref="refRangeTable"
          class="position-relative"
          :items="item.work_days"
          
          :fields="workDayColumns"
          primary-key="id" 
          >

          <template #cell(value)="items">
            
            <span>{{ items.item.value}}</span>
          </template>

        </b-table>

        <b-row v-if="item.kpi_contract == 'yes'">
        
          <b-col
          cols="12"
          class="mb-2 mt-2"
          >
            <h4 class="mb-0">
              KPI Minutes
            </h4>
            
          </b-col>
        </b-row>

        <b-table sticky-header
          ref="refRangeTable"
          class="position-relative"
          :items="item.minutes"
          v-if="item.kpi_contract == 'yes'"
          :fields="kpiMinuteColumns"
          primary-key="id" 
          >

          <template #cell(value)="items">
            
            <span>{{ items.item.value}}</span>
          </template>

        </b-table>
        
        <hr v-if="item.show_custom_message == 'yes'">
        <b-row v-if="item.show_custom_message == 'yes'">
        
          <b-col cols="12" class="mb-2 mt-2">
            <h4 class="mb-0">
              QR Code Configuration
            </h4>
          </b-col>
        </b-row>

        <b-table sticky-header v-if="item.show_custom_message == 'yes'"
          ref="refRangeTable"
          class="position-relative"
          :items="[item.message]"
          :fields="messageColumns"
          primary-key="id" 
        >
          <template #cell(message)="items">            
            <span>{{ items.item}}</span>
          </template>
        </b-table>

        <hr v-if="item.site_type == 'performance_basis'">
        <b-row v-if="item.site_type == 'performance_basis'">
        
          <b-col cols="12" class="mb-2 mt-2">
            <h4 class="mb-0">
              Severity Level
            </h4>
          </b-col>
        </b-row>

        <b-table sticky-header v-if="item.site_type == 'performance_basis'"
          ref="refRangeTable"
          class="position-relative"
          :items="item.severality_levels"
          :fields="severality_fields"
          primary-key="id" 
        >
          <template #cell(name)="items">            
            <span>{{ items.item.name}}</span>
          </template>

          <template #cell(minute)="items">            
            <span>{{ items.item.minute}}</span>
          </template>
        </b-table>

        

        

        <hr>
        <b-row>
        
          <b-col
          cols="12"
          class="mb-2 mt-2"
          >
            <h4 class="mb-0">
              Notifications
            </h4>
            
          </b-col>
        </b-row>

        <b-table sticky-header
          ref="refRangeTable"
          class="position-relative"
          :items="item.notifications"
          
          :fields="notificationColumns"
           
          >

          <template #cell(text)="items">
            
            <span>{{ items.item.text |capitalize}}</span>
          </template>

          <template #cell(value)="items">
            
            <span>{{ getRoles(items.item.value)}}</span>
          </template>

          

        </b-table>

        <hr>
        <div v-if="item.contract_sum != '' &&  item.contract_sum != null">

        <b-row >
          <b-col
          cols="4"
          class="mb-2 mt-2"
          >
            <h4 class="mb-0">
              Contract Sum 
            </h4>
            
          </b-col>
          <b-col
          cols="3"
          class="mb-2 mt-2"
          >
            <h5 class="mb-0">
              SGD {{mask}} 
            </h5>
            
          </b-col>
          <b-col
          cols="2"
          class="mb-2 mt-1"
          >
            <b-button
            size="sm"
            variant="warning"
            class="mr-1"
            @click="updateDisable()"
            v-if="pwd_verified == 'no'"
          >
            View
          </b-button>
            
          </b-col>
        </b-row>

        <hr>
        </div>


        

        
        <b-row v-if="item.use_logo == 'yes'">

          <b-col
            cols="6"
            class="mb-2 mt-2 margin_top_zero_mobile"
          > 
                <b-col
                cols=""
                class="mb-2 mt-2 mobile_pdng_lft_rgt_zero"
                >
                  <h4 class="mb-0">
                    Logo
                  </h4>
                  
                </b-col>
            

            
                <b-col
                  
                  class="mobile_pdng_lft_rgt_zero"
                > 

                    <b-media class="mb-2 mt-2 inline_media_mobile"  v-viewer>
                      <template #aside>
                        <b-avatar
                          ref="supervisorS"
                          :src="item.logo"
                          variant="light-primary"
                          size="200px"
                          rounded
                        />
                      </template>

                      <viewer :options="{title: false}"></viewer>

                      
                    </b-media>

                    
                    
                  </b-col>
            
          </b-col>

          <b-col
            cols="6"
            class="mb-2 mt-2 margin_top_zero_mobile"
          > 
            
              
                <b-col
                cols=""
                class="mb-2 mt-2 mobile_pdng_lft_rgt_zero"
                >
                  <h4 class="mb-0">
                    App Logo
                  </h4>
                  
                </b-col>
            

            
                <b-col
                class="mobile_pdng_lft_rgt_zero"                  
                  
                > 
              
                    <b-media class="mb-2 mt-2 inline_media_mobile" v-viewer>
                      <template #aside>
                        <b-avatar
                          ref="clientS"
                          :src="item.app_logo"
                          variant="light-primary"
                          size="200px"
                          rounded
                        />
                      </template>

                      <viewer :options="{title: false}"></viewer>

                      
                    </b-media>

                    
                    
                  </b-col>
            
          </b-col>

          
        </b-row>

        

        
      </b-card>

      <b-modal
        id="pass"
        ref="pass"
        cancel-variant="outline-secondary"
        ok-variant="outline-warning"
        ok-title="Submit"
        cancel-title="Close"
        centered
        title="Verify Password"
        @ok="verifyPassword"
        no-close-on-backdrop
      >

        <b-alert
          v-model="popshowDismissibleAlert"
          variant="danger"
          dismissible
          class="mb-1"
        >
          <div class="alert-body">
            {{error_message}}
          </div>
        </b-alert>

        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group
                  label="Password"
                  class="required"
                >
                  <b-form-input
                    placeholder=""
                    v-model="password"
                  />
              </b-form-group>
            </b-col>
          </b-row>      
        </b-form>
      </b-modal>

    </div>

  </div>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BAlert, BTable,BMedia,BForm,BFormTextarea,BModal,BBadge,BCardText,BFormInput,BFormGroup,BFormSelect,BFormSelectOption,BBreadcrumb
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
import 'viewerjs/dist/viewer.css'
import Datepicker from 'vuejs-datepicker';

var moment = require('moment-timezone');
export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BAlert, BTable,BMedia,BForm,BFormTextarea,BModal,BBadge,BCardText,BFormInput,BFormGroup,BFormSelect,BFormSelectOption,Datepicker,BBreadcrumb
  },
  filters: {
      minutes: function(value) {
          var arr = [];
          value.forEach(item => {
            arr.push(item.value);
          })

          return arr.join(', ');
      }
  },
  data() {
    return {
      pwd_verified:'no',
      password:'',
      mask:'',
      item:null,
      showDismissibleAlert:false,
      popshowDismissibleAlert:false,
      error_message:'',
      doc_icon: require('@/assets/images/doc.png'),
      shiftColumns : [
          { key: 'name', label: 'Name', sortable: false , thStyle:  {width: '20%'}},
          { key: 'start_day', label: 'Start Day', sortable: false , thStyle:  {width: '15%'}}, 
          { key: 'end_day', label: 'End Day', sortable: false , thStyle:  {width: '15%'}}, 
          { key: 'start_time', label: 'Start Time', sortable: false , thStyle:  {width: '10%'}}, 
          { key: 'end_time', label: 'End Time', sortable: false , thStyle:  {width: '10%'}},
          { key: 'holiday', label: 'Include Holiday', sortable: false , thStyle:  {width: '10%'}},  
          { key: 'crew', label: 'Crew', sortable: false , thStyle:  {width: '10%'}},
          { key: 'supervisor', label: 'Supervisor', sortable: false , thStyle:  {width: '10%'}},
      ],
      contractColumns : [
          { key: 'start_date', label: 'Start Date', sortable: false },
          { key: 'end_date', label: 'End Date', sortable: false }, 
      ],
      notificationColumns : [
          { key: 'text', label: 'Notify On', sortable: false , thStyle:  {width: '60%'}},
          { key: 'value', label: 'User', sortable: false , thStyle:  {width: '40%'}}, 
      ],
      custom_forms : [
          { key: 'title', label: 'title', sortable: false , thStyle:  {width: '50%'}},
          { key: 'validateLevelOne', label: 'Level 1', sortable: false , thStyle:  {width: '25%'}},
          { key: 'validateLevelTwo', label: 'Level 2', sortable: false , thStyle:  {width: '25%'}}
      ],

      workScheduleColumns : [

          { key: 'startTime', label: 'Start Time', sortable: false , thStyle:  {width: '50%'}},
          { key: 'endTime', label: 'End Time', sortable: false , thStyle:  {width: '50%'}}
      ],

      workDayColumns : [

          { key: 'value', label: 'No. of working Days', sortable: false , thStyle:  {width: '100%'}},
          
      ],

      kpiMinuteColumns : [

          { key: 'value', label: 'No. of Minutes', sortable: false , thStyle:  {width: '100%'}},
          
      ],
      
      messageColumns : [
          { key: 'message', label: 'Message', sortable: false , thStyle:  {width: '100%'}},          
      ],

      severality_fields : [
          { key: 'name', label: 'Level', sortable: false , thStyle:  {width: '50%'}},  
          { key: 'minute', label: 'No. of Minutes', sortable: false , thStyle:  {width: '50%'}},  
      ],

      mapStartLocation: { lat: 1.3521, lng: 103.8198 },
      zoom:17,
      paths:[],
      
    }

  },
  directives: {
    Ripple,
  },
  methods: {
    showAlert() {
            
        Swal.fire({
          position: 'center',
          icon: 'error',
          text: this.error_message,
          showConfirmButton: false,
          timer: 3000
        })
    },

    siteDetail(){
      return this.$store.dispatch(POST_API, {
           data:{
             id:this.$route.params.id,
           },
           api: '/api/site-detail'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
            } else {
                
                this.item = this.$store.getters.getResults.data;
                
                if(this.item.contract_sum != '' && this.item.contract_sum.length > 0){

                  this.mask = this.item.contract_sum.replace(/\d/g,"X")

                }

                if (this.item.geofence.length > 0) {

                  this.paths = [];
                  this.paths.push(this.item.geofence);

                }

                this.mapStartLocation.lat = this.item.lat;
                this.mapStartLocation.lng = this.item.long;

                
                return this.item;
            }
        });
    },
    updateDisable(){
      
      this.popshowDismissibleAlert = false;

      this.$refs['pass'].show();
    },
    verifyPassword(e){
      e.preventDefault();

      return this.$store.dispatch(POST_API, {
        data:{
              user_id: this.$store.getters.currentUser._id,
              password:this.password
            },
        api:"/api/verify-password",
          })
          .then(() => {
              if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.popshowDismissibleAlert = true;
              } else {
                  this.pwd_verified = 'yes';
                  this.mask = this.item.contract_sum
                  this.$refs['pass'].hide();
                
              }
          });
    },
    formatType(format) {
      return moment().tz('Asia/Singapore').format(format);
    },
    breadCrumb(){
      var item = [{
          to:{name:'client-dashboard'},
          text: 'Dashboard',
        },{
          to: null, // hyperlink
          text: 'Project Site' // crumb text
        },
        {
          to: {name:'job-sites'},
          text: 'Sites', // crumb text
        },
        {
          to: null, // hyperlink
          text: 'Project Site Details', // crumb text
          active: true
        }
      ]

      return item;
    },
    getRoles(value){
      var arr = [];
      value.forEach(item => { arr.push(item.name);})
      if (arr.length > 0) {
        return arr.join(', ');
      }else{
        return 'Off';
      }
    }
    
    
  },
  mounted(){
    this.siteDetail();
    
    
    
  }
  
  
}
</script>

<style>

</style>
